import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import jobroleService from "../jobroles/jobroleService";
import { getToken } from "../../utils/getToken";

const initialState = {
  jobroles: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: null,
  buttonLoading: false,
};

//Get All Job Roles
export const alljobroles = createAsyncThunk(
  "admin/job-role",
  async (data, thunkAPI) => {
    const token = getToken();
    try {
      if (token) {
        return await jobroleService.getAllJobroles(token, data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Toggle Industries Status
export const toggleStatus = createAsyncThunk(
  "admin/jobrole/status",
  async (data, thunkAPI) => {
    const token = getToken();
    const { jobrole_id, pageno } = data;
    try {
      if (token) {
        return await jobroleService.toggleStatus(token, jobrole_id, pageno);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Job Role
export const createJobrole = createAsyncThunk(
  "admin/jobrole",
  async (data, thunkAPI) => {
    const token = getToken();
    const { pageno, title, icon, price, industry_id, type } = data;
    const createObj = {
      title,
      image: icon,
      price,
      industry_id,
      type,
    };
    try {
      if (token) {
        return await jobroleService.createJobrole(token, createObj, pageno);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Update Job Role
export const updateJobrole = createAsyncThunk(
  "admin/jobrole/update",
  async (data, thunkAPI) => {
    const token = getToken();
    const { pageno, title, icon, price, _id, type, industry_id } = data;
    const updateObj = {
      title,
      icon: icon,
      price,
      _id,
      type,
      industry_id,
    };
    try {
      if (token) {
        return await jobroleService.updateJobrole(token, updateObj, pageno);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// get industry
export const getIndustry = createAsyncThunk(
  "admin/industry",
  async (id, thunkAPI) => {
    const token = getToken();
    try {
      if (token) {
        return await jobroleService.getIndustry(token, id);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const jobroleSlice = createSlice({
  name: "jobroles",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(alljobroles.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(alljobroles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.jobroles = action.payload;
        state.isError = false;
      })
      .addCase(alljobroles.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = null;
        state.jobroles = null;
      })
      .addCase(toggleStatus.pending, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
      })
      .addCase(toggleStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.jobroles = action.payload;
      })
      .addCase(toggleStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        state.jobroles = null;
      })
      .addCase(createJobrole.pending, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
        state.buttonLoading = true;
      })
      .addCase(createJobrole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.jobroles = action.payload;
        state.buttonLoading = false;
      })
      .addCase(createJobrole.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action?.payload; 
        state.buttonLoading = false;
      })
      .addCase(updateJobrole.pending, (state) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;
        state.message = null;
        state.buttonLoading = true;
      })
      .addCase(updateJobrole.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.jobroles = action.payload;
        state.buttonLoading = false;
      })
      .addCase(updateJobrole.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.buttonLoading = false;
      })
      .addCase(getIndustry.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIndustry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = action.payload.message;
        state.industry = action.payload; // Save the single industry details
      })
      .addCase(getIndustry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.industry = null;
      });
  },
});
export const { reset } = jobroleSlice.actions;

export default jobroleSlice.reducer;
