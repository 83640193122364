import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import industriesService from "../industries/industriesService";
import { getToken } from "../../utils/getToken";

const initialState = {
  industries: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  buttonLoading: false,
};

//Get All Industries
export const allindustries = createAsyncThunk(
  "admin/industries",
  async (data, thunkAPI) => {
    const token = getToken();
    try {
      if (token) {
        return await industriesService.getAllIndustries(token, data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Toggle Industries Status
export const toggleStatus = createAsyncThunk(
  "admin/industry",
  async (data, thunkAPI) => {
    const { industry_id, pageno } = data;
    const token = getToken();
    try {
      if (token) {
        return await industriesService.toggleStatus(token, industry_id, pageno);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addIndustry = createAsyncThunk(
  "admin/addIndustry",
  async (data, thunkAPI) => {
    const token = getToken();
    try {
      if (token) {
        return await industriesService.addIndustry(token, data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editIndustry = createAsyncThunk(
  "admin/editIndustry",
  async (data, thunkAPI) => {
    const token = getToken();
    try {
      if (token) {
        return await industriesService.editIndustry(token, data);
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const industriesSlice = createSlice({
  name: "industries",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(allindustries.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(allindustries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.industries = action.payload;
      })
      .addCase(allindustries.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.industries = null;
      })
      .addCase(toggleStatus.pending, (state) => {
        state.isLoading = false;
      })
      .addCase(toggleStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.industries = action.payload;
      })
      .addCase(toggleStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.industries = null;
      })
      .addCase(addIndustry.pending, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
        state.buttonLoading = true;
      })
      .addCase(addIndustry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.industries.body.push(action.payload);
        state.buttonLoading = false; // Add new industry to the list
      })
      .addCase(addIndustry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.buttonLoading = false;
      })
      .addCase(editIndustry.pending, (state) => {
        state.isLoading = false;
        state.buttonLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.message = null 
      })
      .addCase(editIndustry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.industries.body.push(action.payload); // Add new industry to the list
        state.buttonLoading = false;
      })
      .addCase(editIndustry.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.buttonLoading = false
      });
  },
});
export const { reset } = industriesSlice.actions;

export default industriesSlice.reducer;
